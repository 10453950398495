import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, message, Modal, Input, Tooltip } from "antd";
import { generateRandomString } from "../../../../shared/utils/utils";
import {
  FacebookFilled,
  TrophyOutlined,
  TwitterSquareFilled,
  LinkOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import logo from "../../../../content/hs_logo_white.png";
import modalBannerImage from "../../../../content/refer-banner.svg";

const ReferralCard = ({
  updateClient,
  clientProfile,
  referralCode,
  setReferralCode,
  createCoupon,
}) => {
  const [visible, setVisible] = useState(false);
  const [referralEarnings, setReferralEarnings] = useState(0);

  useEffect(() => {
    if (clientProfile) {
      fetchRedemptions();
    }
  }, [clientProfile]);

  console.log(clientProfile);

  const fetchRedemptions = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/coupons/redemptions/?clientId=${clientProfile?._id}&code=REFER50&referrerType=client`
    );
    setReferralEarnings(res.data.totalRedemptionsAmount);
  };

  const handleJoinNow = async () => {
    const newReferralCode = generateRandomString(6);

    // Create the coupon first
    const coupon = {
      code: newReferralCode,
      amount: 50,
      description: `Referral Code for ${clientProfile?.first} ${clientProfile?.last} - HSF: ${clientProfile?.hsf}`,
      coupon_type: "referral",
      creator: clientProfile?.user,
    };

    const couponRes = await createCoupon(coupon);

    if (!couponRes.error) {
      // If coupon creation successful, update client profile
      const res = await updateClient(clientProfile?._id, {
        referral_code: newReferralCode,
      });

      if (!res.error) {
        message.success("Welcome to the club!");
        setReferralCode(newReferralCode);
        setVisible(false);
      } else {
        message.error("Something went wrong updating your profile!");
      }
    } else {
      message.error("Something went wrong creating your referral code!");
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const renderJoinInfo = () => {
    return (
      <>
        <img src={logo} alt="Logo" width="150px" />
        <div className="mb-3 mt-5">
          <h2 className="text-light">REFERRALS</h2>
        </div>
        <div style={{ color: "#8692A6", fontSize: 14 }}>
          You've been invited to join our referral program.
        </div>
        <div className="mt-5" style={{ width: "80%" }}>
          <Button type="primary" size="large" onClick={handleJoinNow} block>
            Join Now
          </Button>
          <div className="mt-3 mb-3">
            <Button
              size="large"
              type="text"
              block
              style={{ color: "#FFF" }}
              onClick={showModal}
            >
              Learn More
            </Button>
          </div>
        </div>
      </>
    );
  };

  const renderProgressInfo = () => {
    return (
      <div>
        <div className="hint mb-1">
          <div>
            <img src={logo} alt="Logo" width="150px" />
          </div>
          <div className="mb-3 mt-5">
            <h2 className="text-light">REFERRALS</h2>
          </div>
          <div style={{ color: "#8692A6", fontSize: 14 }}>
            <TrophyOutlined /> Referrals have earned you
          </div>
        </div>
        <div
          style={{
            color: "#F54B64",
            fontSize: 50,
            fontWeight: 700,
            marginBottom: 10,
          }}
        >
          ${referralEarnings}
        </div>
        <div className="mb-2">
          <Input
            style={{ width: "100%" }}
            defaultValue={`${process.env.REACT_APP_DOMAIN_URL}/referrals/c/${referralCode}`}
            addonAfter={
              <Tooltip title="Copy to Clipboard">
                <CopyToClipboard
                  text={`${process.env.REACT_APP_DOMAIN_URL}/referrals/c/${referralCode}`}
                  onCopy={() => message.info("Link copied to clipboard")}
                >
                  <Button type="link" icon={<LinkOutlined />} />
                </CopyToClipboard>
              </Tooltip>
            }
          />
          <div
            className="text-center text-light mt-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            {referralCode}
            <Tooltip title="Copy Code to Clipboard">
              <CopyToClipboard
                text={referralCode}
                onCopy={() => message.info("Code copied to clipboard")}
              >
                <Button
                  type="link"
                  icon={<CopyOutlined />}
                  style={{ color: "#fff", padding: "4px" }}
                />
              </CopyToClipboard>
            </Tooltip>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 25,
          }}
        >
          <h4 className="text-light">
            <strong>Share:</strong>
          </h4>
          <div className="float-right">
            <Button
              type="link"
              onClick={() =>
                window.open(
                  `https://twitter.com/intent/tweet?url=https://homesnappers.com/referrals/c/${referralCode}`,
                  "_blank"
                )
              }
              icon={
                <TwitterSquareFilled style={{ color: "#fff", fontSize: 24 }} />
              }
            />
            <Button
              type="link"
              icon={<FacebookFilled style={{ color: "#fff", fontSize: 24 }} />}
              onClick={() =>
                window.open(
                  `https://www.facebook.com/sharer.php?u=https://homesnappers.com/referrals/c/${referralCode}`,
                  "_blank"
                )
              }
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="referral-card-container">
        {!referralCode ? renderJoinInfo() : renderProgressInfo()}
      </div>
      <Modal
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={450}
        bodyStyle={{ padding: 0 }}
      >
        <div className="referral-modal-header">
          <img src={modalBannerImage} width="400" alt="Modal Banner" />
        </div>
        <div className="referral-modal-content">
          <h3 className="mt-2">Refer us and earn!</h3>
          <div>
            Become a member of our referral program and earn{" "}
            <strong>$50</strong> for every agent you refer that books their
            first shoot with us.
          </div>
          <h4>Here's how it works:</h4>
          <ul>
            <li>
              <strong>Click the button below to join</strong>
            </li>
            <li>
              <strong>Copy or share your referral link</strong>
            </li>
            <li>
              <strong>
                Earn a $50 credit whenever an agent books their first shoot with
                your link
              </strong>
            </li>
          </ul>
          <p>
            That's it! And there's no cap. Refer as many agents as you like!
          </p>
          <small className="hint mt-4">
            This program is only open to real estate agents and their support
            team. Homeowners will not qualify.
          </small>
          <div className="mt-5 mb-5" style={{ width: "60%" }}>
            <Button type="primary" size="large" onClick={handleJoinNow} block>
              Join Now
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReferralCard;
