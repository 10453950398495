import React, { useState, useEffect } from "react";
import { Layout, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "../App.scss";
import Navigation from "../admin/Container/Navigation";
import VerticalNavigation from "../admin/Container/VerticalNavigation";
import AdminRoutes from "../routes/AdminRoutes";
import { Provider } from "react-redux";
import store from "../redux/store";
import { loadCurrentUser } from "../redux/action/authAction";
import { BrowserRouter as Router } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import logo from "../content/hs_logo_on_light.png";
import logoWebp from "../content/hs_logo_on_light.webp";

const { Content, Footer, Header } = Layout;

export const AdminLayout = () => {
  const [adminState, setAdminState] = useState({
    currentPath: "/admin",
    menuKey: "",
  });
  const [visible, setVisible] = useState(false);

  const auth = store.getState().auth;
  const windowSize = useWindowSize();

  useEffect(() => {
    store.dispatch(loadCurrentUser());
    // showNotification();
  }, []);

  const handleCurrentPath = (path, index) => {
    setAdminState({
      currentPath: path.replace("/admin", ""),
      menuKey: index,
    });
  };

  // const handleCloseNotification = (key) => {
  //   notification.close(key);
  //   localStorage.setItem("show-notification", false);
  // };

  // const showNotification = () => {
  //   const key = `open${Date.now()}`;
  //   const btn = (
  //     <Button
  //       type="primary"
  //       size="small"
  //       onClick={() => handleCloseNotification(key)}
  //     >
  //       Don't Show this Again
  //     </Button>
  //   );

  //   const showNotification = JSON.parse(
  //     localStorage.getItem("show-notification")
  //   );

  //   if (showNotification === null || showNotification) {
  //     notification.info({
  //       message: "Please Pardon the Delay",
  //       description:
  //         "Our support team is currently without power. We are sorry for the inconvenience and will return any calls  and messages as soon as we are able.",
  //       placement: "topRight",
  //       duration: 0,
  //       key,
  //       btn,
  //     });
  //   }
  // };

  const closeDrawer = () => {
    setVisible(false);
  };

  return (
    <Provider store={store}>
      <Layout className="layout">
        <Router>
          <Header className="appHeader">
            <div className="menu-wrapper">
              <div className="logo">
                <picture>
                  <source srcSet={logoWebp} type="image/webp" />
                  <img src={logo} alt="logo" />
                </picture>
              </div>
              {windowSize === "sm" || windowSize === "md" ? (
                <div className="hamburger">
                  <Button
                    onClick={() => setVisible(true)}
                    type="link"
                    icon={<MenuOutlined />}
                    size="large"
                  />
                </div>
              ) : (
                <div className="nav-items">
                  <Navigation index={adminState.menuKey} />
                </div>
              )}
            </div>
          </Header>
          <Content style={{ marginTop: "70px", minHeight: "100vh" }}>
            <AdminRoutes handleCurrentPath={handleCurrentPath} />
            <Drawer
              title="MENU"
              placement="right"
              closable={true}
              onClose={closeDrawer}
              visible={visible}
              destroyOnClose={true}
            >
              <VerticalNavigation closeDrawer={closeDrawer} />
            </Drawer>
          </Content>
          {auth.isAuthenticated && (
            <Footer
              style={{
                borderTop: "1px solid #e8e8e8",
                marginTop: "auto",
                backgroundColor: "#FFF",
                color: "#777",
                textAlign: "center",
                left: 0,
                bottom: 0,
                right: 0,
                height: "50px",
                paddingTop: 20,
                fontSize: "0.8rem",
              }}
            >
              © HOME SNAPPERS {new Date().getFullYear()}. ALL RIGHTS RESERVED.
            </Footer>
          )}
        </Router>
      </Layout>
    </Provider>
  );
};

export default AdminLayout;
